import React, { useState, useRef, useEffect } from "react";
import "./Details.scss";
import MainLayout from "../../layouts/MainLayout";
import { Form, useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";


interface DetailsProps {
  onSubmit: (values: any) => void;
}


const Details: React.FC<DetailsProps> = ({ onSubmit }) => {
  const MobileNumberValidation = Yup.object().shape({
    consumerMobile: Yup.string()
      .required("*Please enter consumer mobile number")
      .matches(/^\d{10}$/, "*Please enter consumer mobile number"),
    uniqueCode: Yup.string()
      .required("*Please enter a unique code")
      .matches(/^[A-Za-z0-9]{6,10}$/, "*Unique code must be 6-10 alphanumeric characters"),
  });
  const navigate = useNavigate();


  return (
    <MainLayout className="details-page">
      <div className="details-container">
        <p className="form-title">ENTER CONSUMER DETAILS</p>

        <Formik
          initialValues={{
            consumerMobile: "",
            uniqueCode: "",
          }}
          validationSchema={MobileNumberValidation}
          onSubmit={async (values, { setErrors }) => {
            try {
              const response = await API.saveCODEDetails(values.consumerMobile, values.uniqueCode);
              gtagTrackEvent(GA_EVENTS.Details_Submit);


              // Check the isRegistered value from the response
              if (response.isRegistered === 1) {
                navigate(ROUTES.REWARD); // Navigate to reward page
              } else {
                navigate(ROUTES.PARTICPATED, { state: { discountAmount: response.discountAmount } }); // Navigate to the other page
              }

              onSubmit(values); // Execute additional actions if needed
            } catch (err: any) {
              console.error("Error submitting details", err);
              setErrors({ uniqueCode: err.message });
            }
          }}
        >
          {({ handleSubmit }) => (
            <form className="mobile-number-form" onSubmit={handleSubmit}>
              <div className="input-group" >
                <label htmlFor="consumerMobile"></label>
                <Field
                  type="text"
                  id="consumerMobile"
                  name="consumerMobile"
                  placeholder="Enter consumer mobile number*"
                  maxLength={10}
                />

                <ErrorMessage
                  name="consumerMobile"
                  component="p"
                  className="error"
                />
              </div>
              <div className="input-group">
                <label htmlFor="uniqueCode"></label>
                <Field
                  type="text"
                  id="uniqueCode"
                  name="uniqueCode"
                  placeholder="Enter Unique Code*"
                  maxLength={8}
                />
                <ErrorMessage
                  name="uniqueCode"
                  component="p"
                  className="error"
                />
              </div>

              <div className="payment-btn-container">
                <button className="btn btn-primary" type="submit" >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default Details;
