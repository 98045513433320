import "./Participated.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../hooks/useAuthentication";
import Confetti from "react-confetti";

export const Participated = () => {
  const { state } = useLocation();

  return (
    <MainLayout className="register-page">

      {/* <div className="congrats-form"> */}
      <div className="Participated-container">
        {/* <p className="congratulation-title">Congratulations!</p> */}
        <p className="Participated-text">
          THE UNIQUE CODE HAS BEEN<br />SUCCESSFULLY VERIFIED
        </p>
        <p className="Participated-sub-text">Please provide the customer<br />with ₹{state.discountAmount || 250} discount on their <br />Nestlé Purina Pro Plan purchase</p>
        <p className="Participated-sub-text">Your cashback will be credited <br />within 48 business hours, <br />post validation.</p>

      </div>
      {/* </div> */}
    </MainLayout>
  );
};
